import React, { useEffect, useState } from 'react';
import styles from './deal.module.css';
import { textStyles } from '../../../constants/textStyles';
import HookForm from '../../../components/HookForm/HookForm';
import SelectClients from '../../../components/Selects/SelectClients';
import SelectDealStatuses from '../../../components/Selects/SelectDealStatuses';
import SelectPaymentTypes from '../../../components/Selects/SelectPaymentTypes';
import Modal from '../../../components/Modal/Modal';
import Cars from '../../Car/Cars';
import DealCreateCarsModal from './DealCreateCarsModal';
import Select from '../../../components/Select/Select';
import SelectManager from '../../../components/Selects/SelectManager';
import DocsInputNew from '../../../components/Inputs/DocsInput/DocsInputNew';
import SelectDocumentType from '../../../components/Selects/SelectDocumentType';
import {
  DEAL_STATUS_ACT_UPLOADED,
  DEAL_STATUS_CAR_ISSUED,
  DEAL_STATUS_WAIRING_REGISTRATION,
  DEAL_STATUS_WAITING_FOR_REGISTRATION_BY_CLIENT,
  DOCUMENT_STATUS_SIGNED,
  DOCUMENT_TYPES_CONTRACT,
  DOCUMENT_TYPES_OPTIONS,
  DOCUMENT_TYPES_TECH_PASSPORT,
} from '../../../constants';
import uploadDocumentToDeal from '../../../api/deals/uploadDocumentToDeal';
import editDeal from '../../../api/deals/editDeal';
import LoaderInline from '../../../components/Loader/LoaderInline';
import SelectNotaryDealStatuses from '../../../components/Selects/SelectNotaryDealStatuses';
import Button from '../../../components/Button/Button';
import SelectDealStatusesOptional from '../../../components/Selects/SelectDealStatusesOptional';
import alert from '../../../helpers/alert';
import setStep from '../../../api/deals/setStep';
import AddButton from '../../../components/AddButton/AddButton';
import { colors } from '../../../constants/colors';
import getOgpoLink from '../../../api/shop/getOgpoLink';

const DealCreateOther = ({ onSubmit, deal, update, documents, backHandler, cars }) => {
  const [dealStatus, setDealStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [registrationType, setRegistrationType] = useState(null);
  const [modalOGPO, setModalOGPO] = useState(false);
  const uploadContract = (file) => {
    setLoading(true);
    uploadDocumentToDeal({
      dealId: deal?.id,
      fileTypeId: DOCUMENT_TYPES_TECH_PASSPORT,
      file: file,
    })
      .then(() => {
        alert.success('Техпаспорт успешно загружен');
        update && update();
      })
      .catch(() => {
        alert.error('Ошибка при загрузке документа');
        setLoading(false);
      });
  };

  const isTechPassportUploaded = () => documents?.find((doc) => doc?.typeId === DOCUMENT_TYPES_TECH_PASSPORT);

  const saveDeal = async () => {
    console.log('DEAL STATUS', dealStatus);
    if (!isTechPassportUploaded() && deal?.status?.id != DEAL_STATUS_WAITING_FOR_REGISTRATION_BY_CLIENT) {
      return alert.error('Техпаспорт не загружен');
    }

    setLoading(true);
    if (dealStatus) {
      await editDeal({
        id: deal?.id,
        dealId: deal?.id,
        statusId: dealStatus?.value,
      }).then(() => {
        if (dealStatus?.value === DEAL_STATUS_CAR_ISSUED) {
          setStep({
            dealId: deal?.id,
            step: 10,
          }).then(() => {
            update && update();
          });
        } else {
          update && update();
        }
      });
    }
  };

  useEffect(() => {
    setDealStatus({
      label: deal?.status?.title,
      value: deal?.status?.id,
    });
  }, [deal]);

  const [ogpoModal, setOgpoModal] = useState(false);
  const [ogpoLink, setOgpoLink] = useState(null);
  const [ogpoLoading, setOgpoLoading] = useState(false);

  const openOGPO = () => {
    setOgpoLoading(true);
    getOgpoLink(cars[0]?.orderId)
      .then((res) => {
        if(res.data?.errorMessage) {
          return alert.error(res.data?.errorMessage);
        }

        setOgpoLink(res?.data);
        setOgpoModal(true);
        setModalOGPO(false);
      })
      .catch((err) => {
        alert.error('Ошибка при получении ссылки покупки ОГПО');
      })
      .finally(() => {
        setOgpoLoading(false);
      });
  };

  if (loading) {
    return (
      <div style={{ padding: '10px 20px', backgroundColor: 'white', marginTop: '20px', border: '1px solid #E0E0E0' }}>
        <LoaderInline />
      </div>
    );
  }

  return (
    <>
      {deal?.status?.id === DEAL_STATUS_WAITING_FOR_REGISTRATION_BY_CLIENT ? (
        <div style={{ marginBottom: 20 }}>
          <div style={{ display: 'flex', alignItems: 'center', columnGap: '20px', marginTop: 20, marginBottom: 20 }}>
            <p style={textStyles.formTitle}>Регистрация ТС</p>
          </div>
          <Select
            label="Статус сделки"
            placeholder={'Статус сделки'}
            options={[
              {
                value: DEAL_STATUS_WAITING_FOR_REGISTRATION_BY_CLIENT,
                label: 'Ожидает постановки ТС на учет клиентом',
              },
              {
                value: DEAL_STATUS_CAR_ISSUED,
                label: 'Машина выдана',
              },
            ]}
            onChange={setDealStatus}
          />
        </div>
      ) : (
        <>
          <div style={{ marginBottom: 40 }}>
            {documents?.map((document) => (
              <a
                href={document?.url}
                target="_blank"
                style={{
                  gap: 7,
                  display: 'grid',
                  gridTemplateColumns: 'auto 1fr',
                  alignItems: 'center',
                  marginBottom: 20,
                }}
                rel="noreferrer"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                  <path
                    d="M2 16H18V9H20V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V9H2V16ZM12 6H17L10 13L3 6H8V0H12V6Z"
                    fill="#B45436"
                  />
                </svg>
                <a style={{ color: '#B45436', fontSize: 16, fontWeight: 400 }}>
                  {document?.type?.title} - {document?.url?.split('/')?.pop()}
                </a>
              </a>
            ))}
          </div>

          <div style={{ display: 'flex', alignItems: 'center', columnGap: '20px', marginTop: 20, marginBottom: 20 }}>
            <p style={textStyles.formTitle}>Загрузить скан техпаспорта </p>
          </div>

          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 20, marginBottom: 30 }}>
            <SelectDocumentType
              style={{ width: '100%' }}
              defaultValue={DOCUMENT_TYPES_OPTIONS?.find((i) => i.value === DOCUMENT_TYPES_TECH_PASSPORT)}
              disabled={true}
            />
            <DocsInputNew
              label="Файл документа"
              text="Файл документа"
              name="fileTypeId"
              id="file"
              onChange={uploadContract}
            />
          </div>

          <div style={{ marginBottom: '30px', width: '400px' }}>
            <SelectDealStatusesOptional
              value={dealStatus}
              onChange={(value) => {
                setDealStatus(value);
              }}
              optionalStatuses={[DEAL_STATUS_WAIRING_REGISTRATION, DEAL_STATUS_CAR_ISSUED]}
            />
          </div>
        </>
      )}

      {(isTechPassportUploaded() || deal?.status?.id === DEAL_STATUS_WAITING_FOR_REGISTRATION_BY_CLIENT) && (
        <>
          <div style={{ marginBottom: 20 }}>
            <div style={{ display: 'flex', alignItems: 'center', columnGap: '20px', marginTop: 20, marginBottom: 20 }}>
              <p style={textStyles.formTitle}>Страхование</p>
            </div>
            <AddButton
              text="Добавить в сделку страховку ОГПО"
              onClick={(e) => {
                e.preventDefault();
                setModalOGPO(true);
              }}
            />
          </div>
        </>
      )}

      <div style={{ display: 'flex', gap: '20px' }}>
        <Button text={'Сохранить изменения'} onClick={saveDeal} fill style={{ width: '280px' }} />
        {backHandler && backHandler()}
      </div>

      <Modal
        isOpen={modalOGPO}
        onRequestClose={() => {
          setModalOGPO(false);
        }}
        width={460}
        contentStyle={{ height: 'auto', inset: '40px 40px auto 350px' }}
      >
        {ogpoLoading ? (
          <LoaderInline />
        ) : (
          <>
            <h3
              style={{
                ...textStyles.formTitle,
                color: colors.blackPearl,
                textAlign: 'center',
                padding: '0 70px',
                lineHeight: '24px',
                marginBottom: 30,
              }}
            >
              Вы будете перенаправлены на сайт <span style={{ color: colors?.cameo }}>Basel.kz</span> для просчета
              стоимости и покупки страховки ОГПО
            </h3>
            <p style={{ marginBottom: 30, textAlign: 'center' }}>
              После покупки полиса, вы сможете продолжить оформление сделки
            </p>
            <Button text="Перейти" fill style={{ width: '100%' }} onClick={openOGPO} />
          </>
        )}
      </Modal>

      <Modal
        wrapperStyle={{
          height: '100%',
        }}
        isOpen={ogpoModal}
        onRequestClose={() => {
          setOgpoModal(false);
        }}
      >
        <iframe
          src={ogpoLink}
          style={{ height: '100%', width: '100%' }}
          frameborder="0"
          title="Онлайн-продажа страховки ОГПО"
        ></iframe>
      </Modal>
    </>
  );
};

export default DealCreateOther;
