import { useEffect, useState } from 'react';
import Button from '../../../components/Button/Button';
import LoaderInline from '../../../components/Loader/LoaderInline';
import generateUserAgreement from '../../../api/creditPlatform/generateUserAgreement';
import DocsInputNew from '../../../components/Inputs/DocsInput/DocsInputNew';
import { textStyles } from '../../../constants/textStyles';
import uploadDocumentToDeal from '../../../api/deals/uploadDocumentToDeal';
import { DOCUMENT_STATUS_SIGNED, DOCUMENT_TYPES_PERSONAL_DATA_AGREEMENT } from '../../../constants';
import { socket } from '../../../App';
import generateLink from '../../../api/signature/generateLink';
import alert from '../../../helpers/alert';
import extractErrorMessage from '../../../utils/extractErrorMessage';
import phoneBelongsIinValidate from '../../../api/bmg/phoneBelongsIinValidate';
import { SIGN_TYPES } from '../../Public/SignDocuments';
import userSettings from '../../../api/clients/userSettings';

const SignatureType = ({ pickMobile, pickUpload, sendSmsForSign }) => {
  return (
    <>
      <p
        style={{
          ...textStyles.formTitle,
          marginBottom: 20,
          marginTop: 20,
        }}
      >
        Для формирования заявок на кредит клиент должен подписать согласие на обработку персональных данных
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
        }}
      >
        <Button text="Отправить клиенту согласие на подписание в мобильном приложении" fill onClick={pickMobile} />
        <Button text="Подписать и загрузить согласие" fill onClick={pickUpload} />
        <Button text="Отправить СМС со ссылкой на подписание ЭЦП" fill onClick={() => sendSmsForSign(false)} />
        <Button text="Отправить СМС со ссылкой на подписание через OTP" fill onClick={() => sendSmsForSign(true)} />
      </div>
    </>
  );
};

const SendAgreement = ({ agreementDocumentId, onDocumentSigned, loading }) => {
  useEffect(() => {
    socket.on('listenSignatureAgreementDocumentInDeal' + agreementDocumentId, (data) => {
      onDocumentSigned();
    });
    return () => {
      socket.off('listenSignatureAgreementDocumentInDeal' + agreementDocumentId);
    };
  }, [agreementDocumentId, onDocumentSigned]);

  if (loading) return <LoaderInline />;

  return (
    <>
      <div
        style={{
          margin: 30,
        }}
      >
        <LoaderInline />
      </div>
      <p style={{ ...textStyles.formTitle, textAlign: 'center' }}>
        Ожидается подписание согласия на обработку персональных данных клиентом
      </p>

      <div
        style={{
          marginTop: 20,
        }}
      >
        <Button onClick={onDocumentSigned} text="Отправить заявку на кредит" fill full />
      </div>
    </>
  );
};
const UploadAgreement = ({ deal, sendAppeal, agreementFile, onClose }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    uploadDocumentToDeal({
      dealId: deal?.id,
      statusId: DOCUMENT_STATUS_SIGNED,
      fileTypeId: DOCUMENT_TYPES_PERSONAL_DATA_AGREEMENT,
      file,
      signed: true,
    })
      .then(() => {
        sendAppeal().finally(() => setLoading(false));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (!agreementFile || loading) return <LoaderInline />;

  return (
    <>
      <p style={{ ...textStyles.formTitle, textAlign: 'center', margin: '20px 0 40px 0' }}>
        Подписание согласия на обработку персональных данных клиентом
      </p>
      <div
        style={{
          marginBottom: 20,
        }}
      >
        <div style={{ marginBottom: 20, marginTop: 20, display: 'flex', justifyContent: 'center' }}>
          <a
            href={agreementFile}
            target="_blank"
            style={{
              gap: 7,
              display: 'inline-grid',
              gridTemplateColumns: 'auto 1fr',
              alignItems: 'center',
              marginBottom: 5,
            }}
            rel="noreferrer"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
              <path
                d="M2 16H18V9H20V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V9H2V16ZM12 6H17L10 13L3 6H8V0H12V6Z"
                fill="#B45436"
              />
            </svg>
            <a style={{ color: '#B45436', fontSize: 16, fontWeight: 400 }}>
              Скачать согласие на обработку персональных данных
            </a>
          </a>
        </div>
        <DocsInputNew
          id="agreement"
          label={file?.name || 'Загрузить подписанное клиентом согласие на обработку персональных данных'}
          onChange={setFile}
        />
      </div>
      <div
        style={{
          display: 'flex',
          gap: 15,
        }}
      >
        <Button text="Отправить" fill disabled={!file || loading} onClick={onSubmit} />
        <Button
          text="Назад"
          disabled={loading}
          onClick={onClose}
          style={{ color: '#B45436', border: '1px solid #B45436' }}
        />
      </div>
    </>
  );
};

const DealCreateAgreement = ({ deal, sendAppeal, onClose, sendFormLoading }) => {
  const [step, setStep] = useState(1);
  const [agreementDocumentId, setAgreementDocumentId] = useState(null);
  const [loading, setLoading] = useState(false);

  const pickMobile = () => {
    setLoading(true);
    generateUserAgreement({ userid: deal?.client?.id, chunkId: deal?.chunkId })
      .then((res) => {
        setAgreementDocumentId(res?.data?.documentId);
        setStep('mobile');
      })
      .catch(() => {
        alert('Не удалось сгенерировать согласие на обработку персональных данных');
      })
      .finally(() => setLoading(false));
  };

  const [agreementFile, setAgreementFile] = useState(null);
  const pickUpload = () => {
    generateUserAgreement({ userid: deal?.client?.id, chunkId: deal?.chunkId })
      .then((res) => {
        if (!res?.data?.url) {
          alert('Не удалось сгенерировать согласие на обработку персональных данных');
          return;
        }
        setAgreementFile(res?.data?.url);
        window.open(res?.data?.url, '_blank');

        setStep('upload');
      })
      .catch((err) => {
        alert.error(
          extractErrorMessage(err?.response?.data) ||
            'Не удалось сгенерировать согласие на обработку персональных данных'
        );
      });
  };

  const onDocumentSigned = () => {
    sendAppeal();
  };

  const sendSmsForSign = async (otp) => {
    setLoading(true);

    try {
      const isPhoneBelongsToClient = await phoneBelongsIinValidate({
        phone: deal?.client?.phone,
        iin: deal?.client?.iin,
      });
      
      if (!isPhoneBelongsToClient) {
        alert.error('Клиент с этим ИИН не является владельцем введенного номера телефона и не проходит проверку Базы Мобильных Граждан. Попросите клиента предоставить другой номер телефона, либо предложите ему поменять свой номер телефона в Базе Мобильных Граждан.');
        setLoading(false);
        return;
      }
    } catch (e) {
      alert.error('Ошибка проверки номера телефона');
      setLoading(false);
      return;
    }

    generateUserAgreement({ userid: deal?.client?.id, chunkId: deal?.chunkId })
      .then((res) => {
        if (!res?.data?.url) {
          alert.error(
            'Не удалось сгенерировать согласие на обработку персональных данных. Отсутствует ссылка на документ'
          );
          setLoading(false);
          return;
        }
        setAgreementDocumentId(res?.data?.documentId?.[0]);
        generateLink({
          url: res?.data?.url,
          name: 'docs',
          typeId: DOCUMENT_TYPES_PERSONAL_DATA_AGREEMENT,
          documentId: res?.data?.documentId?.[0],
          userId: deal?.client?.id,
          dealId: deal?.id,
          signType: otp ? SIGN_TYPES.OTP : SIGN_TYPES.ECP,
        })
          .then(() => {
            alert.success('СМС успешно отправлено');
            setStep('mobile');
          })
          .catch(() => {
            alert.error('Ошибка отправки СМС');
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        alert.error('Не удалось сгенерировать согласие на обработку персональных данных');
        setLoading(false);
      });
  };

  if (loading) {
    return <LoaderInline />;
  }

  return (
    <>
      {step === 1 && <SignatureType pickMobile={pickMobile} pickUpload={pickUpload} sendSmsForSign={sendSmsForSign} />}
      {step === 'mobile' && (
        <SendAgreement
          onDocumentSigned={onDocumentSigned}
          agreementDocumentId={agreementDocumentId}
          loading={sendFormLoading}
        />
      )}
      {step === 'upload' && (
        <UploadAgreement
          setStep={setStep}
          deal={deal}
          sendAppeal={sendAppeal}
          agreementFile={agreementFile}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default DealCreateAgreement;

