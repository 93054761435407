import React, { useEffect, useState } from 'react';
import styles from '../Client/ClientMainData/clientMainData.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import formatNumber from '../../utils/formatNumber';
import getDealCard from '../../api/deals/getDealCard';
import getDealCars from '../../api/deals/getDealCars';

// components
import ClientCheck from '../../components/ClientCheck/ClientCheck';
import Loader from '../../components/Loader/Loader';
import NotaryForm from '../../components/NotaryForm/NotaryForm';
import SelectPhoto from '../Client/NewClient/ClientCreate/SelectPhoto/SelectPhoto';
import ClientLink from '../../components/ClientLink/ClientLink';
import AddFileButton from '../../components/AddFileButton/AddFileButton';
import SelectNotaryDealStatuses from '../../components/Selects/SelectNotaryDealStatuses';

import Button from '../../components/Button/Button';
import getDealDocuments from '../../api/deals/getDealDocuments';
import { colors } from '../../constants/colors';
import {
  DEAL_STATUS_NOTARIAL_DOCUMENTS_UPLOADED,
  DEAL_STATUS_WAIRING_REGISTRATION,
  DEAL_STATUS_WAITING_FOR_PAYMENT,
  DOCUMENT_TYPES_POWER_OF_ATTORNEY,
  DOCUMENT_TYPES_SPOUSE_CONSENT,
  PAYMENT_TYPES_CREDIT_CARD,
  PAYMENT_TYPES_CREDIT_INVOICE,
  DEAL_STATUS_WAITING_FOR_REGISTRATION_DOCUMENTS,
  DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
  DOCUMENT_TYPES_INVOICE,
  DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
  DOCUMENT_TYPES_GRNZ,
  DOCUMENT_TYPES_TECH_PASSPORT,
  BANKS,
  DEAL_STEPS,
  DOCUMENT_TYPES_GUARANTEE_LETTER,
} from '../../constants';
import uploadDocumentToDeal from '../../api/deals/uploadDocumentToDeal';
import editDeal from '../../api/deals/editDeal';
import alert from '../../helpers/alert';
import setStep from '../../api/deals/setStep';
import Input from '../../components/Inputs/Input/Input';
import generateAllowedDocumentsJson from '../../utils/generateAllowedDocumentsJson';
import getCreditAppeal from '../../api/deals/getCreditAppeal';
import sendMarriedDoc from '../../api/creditPlatform/sendMarriedDoc';

const DealNotary = () => {
  const { id } = useParams();
  const [photo, setPhoto] = useState();
  const [uploadPhoto, setUploadPhoto] = useState();
  const [isDisableSelectPhoto, setDisableSelectPhoto] = useState(true);
  const [dealInfo, setDealInfo] = useState([]);
  const [cars, setCars] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deal, setDeal] = useState({});
  const [dealStatus, setDealStatus] = useState(null);
  const [powerOfAttorneyNumber, setPowerOfAttorneyNumber] = useState('');
  const [dealDocuments, setDealDocuments] = useState([]);
  const [showSpoouseConsent, setShowSpoouseConsent] = useState(true);

  const navigateToClient = (id) => {
    navigate(`/notary/clients/${id}/main-data`);
  };

  useEffect(() => {
    setDealStatus({
      label: deal?.status?.title,
      value: deal?.status?.id,
    });
    setPowerOfAttorneyNumber(deal?.power_of_attorney_number);
  }, [deal]);

  const init = () => {
    getDealCard({ id: id })
      .then(async (res) => {
        const dealInfo = [
          {
            label: 'Клиент',
            defaultValue: res.data?.client ? `${res.data?.client?.name} ${res.data?.client?.surname}` : '',
            component: (
              <div
                style={{ position: 'absolute', right: '5px', top: '0px' }}
                onClick={() => navigateToClient(res.data?.client?.id)}
              >
                <ClientLink client={'Посмотреть карточку клиента'} />
              </div>
            ),
          },
          {
            defaultValue: new Date(res.data.createdAt).toLocaleDateString(),
            label: 'Дата сделки',
          },
          {
            label: 'Статус сделки',
            defaultValue: res?.data?.status?.title,
          },
          {
            label: 'Форма оплаты',
            defaultValue: res.data.paymentType?.title,
          },
          {
            label: 'Статус оплаты',
            defaultValue: res.data.statusPayment?.title,
          },
          {
            name: 'sum',
            label: 'Сумма сделки, KZT',
            placeholder: 'Автоматический расчет',
            defaultValue: formatNumber(res.data.sum),
          },
        ];

        const { data: dealDocuments } = await getDealDocuments({ id: id });

        const documents = dealDocuments?.map((doc) => ({
          label: doc?.type?.title,
          component: (
            <a
              href={doc?.url}
              target="_blank"
              rel="noreferrer"
              style={{
                color: colors.cameo,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              Скачать
              <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8 5.5H10V15.5H8V5.5ZM12 9.5H14V15.5H12V9.5ZM4 11.5H6V15.5H4V11.5ZM12 2.5H2V18.5H16V6.5H12V2.5ZM0 1.492C0 0.944 0.447 0.5 0.999 0.5H13L18 5.5V19.493C18.0009 19.6243 17.976 19.7545 17.9266 19.8762C17.8772 19.9979 17.8043 20.1087 17.7121 20.2022C17.6199 20.2957 17.5101 20.3701 17.3892 20.4212C17.2682 20.4723 17.1383 20.4991 17.007 20.5H0.993C0.730378 20.4982 0.479017 20.3931 0.293218 20.2075C0.107418 20.0219 0.00209465 19.7706 0 19.508V1.492Z"
                  fill="#B45436"
                />
              </svg>
            </a>
          ),
        }));

        if (!dealDocuments?.find((doc) => doc?.typeId == DOCUMENT_TYPES_POWER_OF_ATTORNEY)) {
          documents.push({
            labelIcon: 'Доверенность не загружена',
            component: (
              <AddFileButton
                id={'POWER_OF_ATTORNEY'}
                label={'Добавить документ'}
                onChange={(file) => {
                  setLoading(true);
                  uploadDocumentToDeal({
                    dealId: res?.data?.id,
                    fileTypeId: DOCUMENT_TYPES_POWER_OF_ATTORNEY,
                    file,
                  }).then(() => {
                    // setShowSpoouseConsent(false);
                    if (
                      dealDocuments?.find((doc) => doc?.typeId == DOCUMENT_TYPES_SPOUSE_CONSENT) &&
                      (res?.data?.paymentType?.id == PAYMENT_TYPES_CREDIT_CARD ||
                        res?.data?.paymentType?.id == PAYMENT_TYPES_CREDIT_INVOICE)
                    ) {
                      editDeal({
                        id: res?.data?.id,
                        dealId: res?.data?.id,
                        statusId: DEAL_STATUS_NOTARIAL_DOCUMENTS_UPLOADED,
                      })
                        .then(() => {
                          init();
                        })
                        .finally(() => setLoading(false));
                    } else {
                      setLoading(false);
                      init();
                    }
                  });
                }}
              />
            ),
          });
        }

        if (
          !dealDocuments?.find((doc) => doc?.typeId == DOCUMENT_TYPES_SPOUSE_CONSENT) &&
          (res?.data?.paymentType?.id == PAYMENT_TYPES_CREDIT_INVOICE ||
            res?.data?.paymentType?.id == PAYMENT_TYPES_CREDIT_CARD)
        ) {
          documents.push({
            labelIcon: 'Согласие супруга (супруги) на внесудебную реализацию авто не загружено',
            component: (
              <AddFileButton
                id={'SPOUSE_CONSENT'}
                label={'Добавить документ'}
                onChange={(file) => {
                  setLoading(true);
                  uploadDocumentToDeal({
                    dealId: res?.data?.id,
                    fileTypeId: DOCUMENT_TYPES_SPOUSE_CONSENT,
                    file,
                  })
                    .then(async () => {
                      init();
                    })
                    .finally(() => setLoading(false));
                }}
              />
            ),
            spouce: true,
          });
        }

        setDealInfo(dealInfo);
        setDocuments(documents);
        setPhoto(res.data?.client?.photo);
        setDeal(res.data);
        setDealDocuments(dealDocuments);
      })
      .then(() => {
        getDealCars({ id: id }).then((res) => {
          const car = res.data.cars[0];
          const carInfo = [
            { label: 'Автомобиль', defaultValue: `${car.mark} ${car.model} (ID ${car.id})` },
            { label: 'Комплектация', defaultValue: car.complectation.title },
            { label: 'Год выпуска', defaultValue: car.issueYear },
            { label: 'VIN', defaultValue: car.vin },
            { label: 'Дилерский центр', defaultValue: 'Kia DOSCAR Алматы' },
            { label: 'Цвет', defaultValue: car.color },
            { label: 'Тип двигателя', defaultValue: car.complectation?.engine?.title },
          ];

          setCars(carInfo);
        });
      })
      .catch((error) => {
        console.log(error, '[error]');
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    init();
  }, []);
  const navigate = useNavigate();

  const done = () => {
    alert.success('Изменения успешно сохранены');
    navigate('/notary/deals');
  };

  const saveDeal = async () => {
    if (!dealDocuments?.find((doc) => doc?.typeId === DOCUMENT_TYPES_POWER_OF_ATTORNEY)) {
      return alert.error('Доверенность не загружена');
    }

    // if (
    //   !dealDocuments?.find((doc) => doc?.typeId === DOCUMENT_TYPES_SPOUSE_CONSENT) &&
    //   (deal?.paymentType?.id === PAYMENT_TYPES_CREDIT_CARD || deal?.paymentType?.id === PAYMENT_TYPES_CREDIT_INVOICE)
    // ) {
    //   return alert.error('Согласие супруга (супруги) на внесудебную реализацию авто не загружено');
    // }

    let newStatus = null;

    if (deal?.paymentType?.id === PAYMENT_TYPES_CREDIT_CARD || deal?.paymentType?.id === PAYMENT_TYPES_CREDIT_INVOICE) {
      let status = dealDocuments?.find((doc) => doc?.typeId === DOCUMENT_TYPES_SPOUSE_CONSENT)
        ? DEAL_STATUS_WAITING_FOR_REGISTRATION_DOCUMENTS
        : DEAL_STATUS_WAIRING_REGISTRATION;

      newStatus = dealStatus?.value == DEAL_STATUS_NOTARIAL_DOCUMENTS_UPLOADED ? status : dealStatus?.value;
    } else {
      newStatus =
        dealStatus?.value == DEAL_STATUS_NOTARIAL_DOCUMENTS_UPLOADED
          ? DEAL_STATUS_WAIRING_REGISTRATION
          : dealStatus?.value;
    }

    setLoading(true);
    if (dealStatus) {
      const creditAppeals = await getCreditAppeal({ chunkId: deal?.chunkId });
      const chosenBankAppeal = creditAppeals?.data?.appeals?.find((appeal) => appeal?.selected);

      if (chosenBankAppeal?.bank?.id === BANKS.HALYK) {
        setLoading(true);
        return sendMarriedDoc({ appealId: chosenBankAppeal?.id })
          .then(() => {
            editDeal({
              id: deal?.id,
              dealId: deal?.id,
              statusId: DEAL_STATUS_WAITING_FOR_PAYMENT,
              step: DEAL_STEPS.HALYK.WAITING_FOR_PAYMENT,
              power_of_attorney_number: powerOfAttorneyNumber ? powerOfAttorneyNumber : deal?.power_of_attorney_number,
              ...generateAllowedDocumentsJson([
                DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
                DOCUMENT_TYPES_INVOICE,
                DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
                DOCUMENT_TYPES_POWER_OF_ATTORNEY,
                DOCUMENT_TYPES_SPOUSE_CONSENT,
                DOCUMENT_TYPES_GUARANTEE_LETTER,
              ]),
            }).then(() => {
              done();
            });
          })
          .catch(() => {
            alert.error('Не удалось отправить согласие супруга (супруги) на внесудебную реализацию авто');
          })
          .finally(() => {
            setLoading(false);
          });
      }

      await editDeal({
        id: deal?.id,
        dealId: deal?.id,
        statusId: newStatus,
        power_of_attorney_number: powerOfAttorneyNumber ? powerOfAttorneyNumber : deal?.power_of_attorney_number,
        ...generateAllowedDocumentsJson([
          DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
          DOCUMENT_TYPES_INVOICE,
          DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
          DOCUMENT_TYPES_POWER_OF_ATTORNEY,
          DOCUMENT_TYPES_SPOUSE_CONSENT,
        ]),
      }).then(() => {
        if (dealStatus?.value == DEAL_STATUS_NOTARIAL_DOCUMENTS_UPLOADED) {
          setStep({ dealId: deal?.id, step: 6 }).then(() => {
            done();
          });
        } else {
          done();
        }
      });
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={styles.wrapper} style={{ paddingBottom: 100 }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <SelectPhoto
                photo={photo}
                setPhoto={setPhoto}
                setUploadPhoto={setUploadPhoto}
                style={{ flex: 1 }}
                disabled={isDisableSelectPhoto}
              />
              <p className={styles.date} style={{ margin: '20px 0' }}>
                Клиент зарегистрирован: <span>01.09.2022</span>
              </p>
              <ClientCheck
                list={[
                  {
                    title: 'Нотариальные док-ы',
                    status: true,
                    statusLabel: 'оформлены',
                  },
                ]}
              />
            </div>
            <div className={styles.forms}>
              <NotaryForm title={'Информация о сделке'} form={dealInfo} />
              <NotaryForm title={'Автомобиль'} form={cars} />
              <NotaryForm
                title={'Документы'}
                form={showSpoouseConsent ? documents : documents?.filter((i) => !i.spouce)}
              />
              <div style={{ marginBottom: '30px', display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 20 }}>
                <SelectNotaryDealStatuses
                  value={dealStatus}
                  onChange={(value) => {
                    setDealStatus(value);
                  }}
                />
                <Input
                  label="Номер доверенности"
                  style={{ width: '100%' }}
                  onChange={(value) => {
                    setPowerOfAttorneyNumber(value.target.value);
                  }}
                  defaultValue={powerOfAttorneyNumber}
                />
              </div>

              <Button
                text={'Сохранить изменения'}
                onClick={saveDeal}
                fill
                style={{ width: '280px', marginBottom: '30px' }}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DealNotary;
