import React from 'react';
import styles from './button.module.css';

const Button = ({ text, count, onClick, fill, disabled, type, style = {}, children, newLabel, full }) => {
  return (
    <button type={type} className={fill ? styles.fill : styles.button} style={{
      width: full ? '100%' : 'auto',
      ...style,
    }} onClick={onClick} disabled={disabled ? disabled : false}>
      {text} {typeof count === 'number' ? `(${count})` : ''}
      {newLabel > 0 && <div style={{
        background: "#B45436",
        color: '#fff',
        borderRadius: 40,
        display: 'inline-block',
        padding: '3px 7px',
        marginLeft: 20
      }}> {newLabel} </div>}
      {children}
    </button>
  );
};

export default Button;
