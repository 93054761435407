import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import SideBarItem from '../SideBarItem/SideBarItem';
import arrow from '../../assets/header/arrowUser.svg';

const hasActiveChilds = (arr, pathname) => {
  console.log(arr, pathname);
  for (let i = 0; i < arr.length; i++) {
    if (pathname === arr[i].path) {
      return true;
    }
    if (arr[i].children && hasActiveChilds(arr[i].children, pathname)) {
      return true;
    }
  }

  return false;
};
const Menu = ({ items }) => {
  const location = useLocation();
  const [menuState, setMenuState] = useState(items.map(() => false));

  const toggleSubmenu = (index) => {
    setMenuState((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  return (
    <>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <NavLink
            to={item.path}
            end={item.path == '/' ? true : false}
            style={({ isActive }) => ({
              display: 'flex',
              background:
                isActive || (location.pathname.split('/')[1] === 'client' && item.name === 'client')
                  ? 'rgba(255, 255, 255, 0.1)'
                  : '',
            })}
          >
            <div style={{ flex: 1 }}>
              <SideBarItem
                title={item.title}
                icon={item.icon}
                path={item.path}
                key={item.id}
                count_1={item.count}
                count_2={item.count_2}
              />
            </div>
            {item.children && (
              <button type="button" onClick={() => toggleSubmenu(index)}>
                <img
                  style={{
                    transform: `rotateZ(${menuState[index] ? 0 : '-90deg'})`,
                  }}
                  src={arrow}
                  alt={'Arrow'}
                />
              </button>
            )}
          </NavLink>
          <div style={{ marginLeft: 35 }}>
            {item.children &&
              (location.pathname == item.path ||
                menuState[index] ||
                hasActiveChilds(item.children, location.pathname)) && <Menu items={item.children} />}
          </div>
        </React.Fragment>
      ))}
    </>
  );
};

export default Menu;
