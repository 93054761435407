export const colors = {
  lightGrey: '#BABABA',
  cameo: '#B45436',
  dimGray: '#6A6A6A',
  blackPearl: '#05141F',
  alto: '#D9D9D9',
  white: '#FFFFFF',
  armadillo: '#404A5F',
  backgroung: '#FBFBFB',
  disabled: '#ededed',
  statusGreen: '#71B183',
  rowRedBG: '#ff00001c',
  rowYellowBG: '#F4F4F4',
  red: '#EB5757',
};
