import { colors } from '../../../constants/colors';
import Button from '../../Button/Button';
import Input from '../Input/Input';

const Counter = ({
  value,
  onIncrement,
  onDecrement,
  height = 35,
  buttonsStyle = {},
  inputStyle = {},
  containerStyle = {},
}) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: 5,
        height,
        margin: 5,
        ...containerStyle,
      }}
    >
      <Button text="-" style={{ border: '1px solid #B45436', padding: 0, ...buttonsStyle }} onClick={onDecrement} />
      <Input
        disabled
        value={value}
        style={{
          padding: 0,
          height,
          textAlign: 'center',
          opacity: value === 0 ? 0.5 : 1,
          borderColor: value === 0 ? colors.lightGrey : colors.blackPearl,
          ...inputStyle,
        }}
      />
      <Button text="+" style={{ border: '1px solid #B45436', padding: 0, ...buttonsStyle }} onClick={onIncrement} />
    </div>
  );
};

export default Counter;
