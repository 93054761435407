import React, { useState, useRef, useEffect, useCallback } from 'react';
import Cookies from 'universal-cookie';
import { useResolvedPath } from 'react-router-dom';
import styles from './sideBar.module.css';

// components
import SideBarItem from '../SideBarItem/SideBarItem';

// icons
// import logo from '../../assets/sidebar/logo.svg';
import cars from '../../assets/sidebar/cars.svg';
import clients from '../../assets/sidebar/clients.svg';
import deals from '../../assets/sidebar/deals.svg';
import equipment from '../../assets/sidebar/equipment.svg';
import services from '../../assets/sidebar/services.svg';
import leads from '../../assets/sidebar/leads.svg';
import profile from '../../assets/sidebar/profile.svg';
import reports from '../../assets/sidebar/report.svg';
import records from '../../assets/sidebar/records.svg';
import arrow from '../../assets/header/arrowUser.svg';
import Menu from './Menu';
import getMenuItemCount from '../../api/sidebar/getMenuItemCount';
import axios from '../../plugins/axios';
import { socket } from '../../App';
import isUserHaveRole from '../../utils/isUserHaveRole';
import {
  ROLE_NOTARY,
  ROLE_MANAGER,
  PERMISSION_CAN_SEE_ALL_LEADS,
  PERMISSION_CAN_SEE_SERVICES,
  PERMISSION_CAN_SEE_RECORDS,
  PERMISSION_CAN_SEE_REPORTS,
  PERMISSION_CAN_SEE_PERMISSIONS,
  PERMISSION_CAN_SEE_ALL_DEALS,
} from '../../constants/roles';
import LoaderInline from '../Loader/LoaderInline';
import { useSelector } from 'react-redux';
import logout from '../../helpers/logout';
import isUserHavePermission from '../../utils/isUserHavePermission';

const SideBar = ({ to }) => {
  const user = useSelector((state) => state.user);
  const getSidebarItems = useCallback(() => {
    return isUserHaveRole(ROLE_NOTARY)
      ? [
          { title: 'Клиенты', name: 'client', icon: clients, path: '/notary/clients', chapterId: 3 },
          { title: 'Сделки', name: 'deal', icon: deals, path: '/notary/deals', chapterId: 5 },
        ]
      : [
          { title: 'Клиенты', name: 'client', icon: clients, path: '/', chapterId: 3, count: 0 },
          {
            title: 'Лиды',
            name: 'lead',
            icon: leads,
            path: '/leads',
            chapterId: 4,
            count: 0,
            count_2: 0,
            show: isUserHavePermission(PERMISSION_CAN_SEE_ALL_LEADS),
          },
          {
            title: 'Продажи',
            name: 'deal',
            icon: deals,
            path: '/deals',
            chapterId: 5,
            count: 0,
            show: isUserHavePermission(PERMISSION_CAN_SEE_ALL_DEALS),
          },
          {
            title: 'Неудачные сделки',
            name: 'rejected-deals',
            icon: deals,
            path: '/deals-rejected',
            chapterId: 23,
            count: 0,
            show: isUserHavePermission(PERMISSION_CAN_SEE_ALL_DEALS),
          },
          {
            title: 'Автомобили',
            name: 'cars',
            icon: cars,
            path: '/cars',
            chapterId: 1,
            count: 0,
            count_2: 0,
            children: [
              { title: 'Марки', path: '/cars/marks' },
              { title: 'Модели', path: '/cars/models' },
              { title: 'Марка и модель', path: '/cars/containers' },
            ],
          },
          // {
          //   title: 'Доп. оборудование',
          //   chapterId: 2,
          //   name: 'equipments',
          //   icon: equipment,
          //   path: '/equipments',
          //   count: 0,
          // },
          {
            title: 'Доп. услуги',
            name: 'additionalServices',
            chapterId: 8,
            icon: services,
            path: '/services',
            count: 0,
            show: isUserHavePermission(PERMISSION_CAN_SEE_SERVICES),
          },
          {
            title: 'Записи',
            name: 'allService',
            icon: records,
            path: '/records',
            children: [
              { title: 'На сервис', path: '/records/service', name: 'service', count: 0, count_2: 0 },
              { title: 'На тест-драйв', path: '/records/test-drive', name: 'testDrive', count: 0, count_2: 0 },
            ],
            count: 0,
            count_2: 0,
            arrow: arrow,
            show: isUserHavePermission(PERMISSION_CAN_SEE_RECORDS),
          },
          {
            title: 'Отчеты',
            name: '',
            icon: reports,
            path: '/reports',
            show: isUserHavePermission(PERMISSION_CAN_SEE_REPORTS),
          },
          { title: 'Профиль', name: '', icon: profile, path: '/profile' },
          {
            title: 'Настройка ролей',
            name: 'permissions',
            icon: equipment,
            path: '/settings/permissions',
            show: isUserHavePermission(PERMISSION_CAN_SEE_PERMISSIONS),
          },
        ].filter((item) => item.show || item.show === undefined);
  }, [user]);

  const [menuItems, setMenuItems] = React.useState([]);

  const { pathname } = useResolvedPath(to);
  const [loading, setLoading] = useState(true);

  const ref = useRef(null);

  const [menuCounter, setMenuCounter] = React.useState({});
  const [rerender, setRerender] = React.useState(0);

  useEffect(() => {
    init();
  }, [user]);

  const init = async () => {
    let test = getSidebarItems();
    const itemCounts = await axios.get('/api/crm/sidebar/getMenuItemCount');

    test.forEach((item) => {
      Object.keys(itemCounts.data).forEach((i, index) => {
        if (item.children) {
          item.children.forEach((child) => {
            if (child.name === i && itemCounts.data[i] > 0) child.count = itemCounts.data[i];
          });
          if (item.name === i && itemCounts.data[i] > 0) item.count = itemCounts.data[i];
        } else {
          if (item.name === i && itemCounts.data[i] > 0) item.count = itemCounts.data[i];
        }
      });
    });

    const notificationCount = await axios.get('/api/notifications/sidebar/getCrmSidebarNotificationsCount');

    if (isUserHaveRole(ROLE_NOTARY)) {
      test[1].count_2 = notificationCount.data.deal;
      test[0].count_2 = notificationCount.data.client;
    } else {
      if (test[4]) {
        test[4].count_2 = notificationCount.data.cars;
      }
      if (test[2]) {
        test[2].count_2 = notificationCount.data.lead;
      }

      if (test[5]) {
        test[5].count_2 = notificationCount.data.equipments;
      }
      if (test[6]) {
        test[6].count_2 = notificationCount.data.additionalServices;
      }
      if (test[7]) {
        test[7].count_2 = notificationCount.data.allService;
      }
      if (test[2]) {
        test[2].count_2 = notificationCount.data.deal;
      }
      if (test[0]) {
        test[0].count_2 = notificationCount.data.client;
      }
      // if (test[6]?.children?.[1]?.count_2) {
      //   test[6].children[1].count_2 = notificationCount.data.service;
      // }
      // if (test[6].children?.[0].count_2) {
      //   test[6].children[0].count_2 = notificationCount.data.testDrive;
      // }
    }

    setRerender(rerender + 1);
    setMenuItems(test);
    setLoading(false);
  };
  React.useEffect(() => {
    socket.on('listenCrmNavigationNotifications', (data) => {
      init();
    });
    return () => {
      socket.off('listenCrmNavigationNotifications');
    };
  }, [user]);

  React.useEffect(() => {
    console.log('USER', user);
  }, [user]);

  return (
    <div id="sidebar" className={styles.sidebar}>
      <div className={styles.sticky}>
        {/* <img src={logo} alt="Logo" className={styles.logo} /> */}
        <nav ref={ref}>{loading ? <LoaderInline /> : <Menu items={menuItems} />}</nav>
        <hr />
        <button onClick={logout} className={styles.exit}>
          Выход
        </button>
      </div>
    </div>
  );
};

export default SideBar;
