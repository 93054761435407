import dots from '../assets/dots.svg';
import indicator from '../assets/indicator.svg';
import { colors } from './colors';
import Status from '../components/Status';
import ClientLink from '../components/ClientLink/ClientLink';
import File from '../components/File/File';
import dayjs from 'dayjs';
import formatNumber from '../utils/formatNumber';
import formatPhoneNumber from '../utils/formatPhoneNumber';
import { toast } from 'react-toastify';
import alert from '../helpers/alert';
import copyToClipboard from '../utils/copyToClipboard';
import Modal from '../components/Modal/Modal';
import DealAutos from '../pages/Deal/DealAutos';
import checkStockCar from '../utils/checkStockCar';
import AddButton from '../components/AddButton/AddButton';
import fixDateTimezone from '../utils/fixDateTimezone';
import formatDate from '../utils/formatDate';
import { COLORS } from '../components/Selects/SelectColor';

export const CLIENTS = [
  {
    Header: 'ФИО клиента',
    accessor: (row) => `${row.name} ${row.surname}`,
    width: '15%',
  },
  {
    Header: 'ИИН',
    accessor: 'iin',
    width: '15%',
  },
  // {
  //   Header: 'Должность',
  //   accessor: 'employe',
  // },
  {
    Header: 'Телефон',
    accessor: (row) => formatPhoneNumber(row.phone),
    width: '15%',
    disableSortBy: true,
  },
  {
    Header: 'E-mail',
    accessor: 'email',
    width: '15%',
    disableSortBy: true,
  },
  {
    Header: 'Город',
    accessor: (row) => row?.city?.title,
    width: '10%',
  },
  {
    Header: 'Язык',
    accessor: 'language.title',
    width: '10%',
    disableSortBy: true,
  },
  {
    Header: 'Статус проверки',
    accessor: (row) => (
      <Status
        status={
          (row?.notarial_verification == 1 && 'ожидает проверки') ||
          (row?.notarial_verification == 2 && 'прошел проверку') ||
          (row?.notarial_verification == 3 && 'не прошел проверку')
        }
        color={row?.notarial_verification == 1 ? colors.dimGray : '#fff'}
        backgroundColor={
          (row?.notarial_verification == 1 && '#fff') ||
          (row?.notarial_verification == 2 && '#9EC5A9') ||
          (row?.notarial_verification == 3 && '#FFA1A1')
        }
      />
    ),
    width: '15%',
  },
  {
    Header: <img src={dots} alt="Dots" />,
    accessor: 'dots',
  },
];
export const NOTARY_CLIENTS = [
  {
    Header: 'ФИО клиента',
    accessor: (row) => `${row.name} ${row.surname}`,
    width: '30%',
  },
  {
    Header: 'ИИН',
    accessor: (row) => (
      <div
        style={{ display: 'inline-flex', alignItems: 'center' }}
        className="hover-lighten"
        onClick={() => {
          copyToClipboard(row.iin);
          alert.success('ИИН успешно скопирован в буфер обмена');
        }}
      >
        <p style={{ minWidth: 90 }}>{row.iin}</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          style={{ marginLeft: 5 }}
        >
          <path
            d="M3.75004 4.04167V1.66667C3.75004 1.4567 3.83345 1.25534 3.98191 1.10687C4.13038 0.958407 4.33174 0.875 4.54171 0.875H9.61946L11.2028 2.45833H15.625C15.835 2.45833 16.0364 2.54174 16.1848 2.69021C16.3333 2.83867 16.4167 3.04004 16.4167 3.25V11.1667C16.4167 11.3766 16.3333 11.578 16.1848 11.7265C16.0364 11.8749 15.835 11.9583 15.625 11.9583H13.25V14.3333C13.25 14.5433 13.1666 14.7447 13.0182 14.8931C12.8697 15.0416 12.6683 15.125 12.4584 15.125H1.37504C1.16508 15.125 0.963714 15.0416 0.815248 14.8931C0.666781 14.7447 0.583374 14.5433 0.583374 14.3333V4.83333C0.583374 4.62337 0.666781 4.42201 0.815248 4.27354C0.963714 4.12507 1.16508 4.04167 1.37504 4.04167H3.75004ZM3.75004 5.625H2.16671V13.5417H11.6667V11.9583H3.75004V5.625ZM5.33337 2.45833V10.375H14.8334V4.04167H10.5473L8.96396 2.45833H5.33337Z"
            fill="#B45436"
          />
        </svg>
      </div>
    ),
    width: '46%',
  },
  {
    Header: 'Статус проверки',
    accessor: (row) => (
      <Status
        status={
          (row?.notarial_verification == 1 && 'ожидает проверки') ||
          (row?.notarial_verification == 2 && 'прошел проверку') ||
          (row?.notarial_verification == 3 && 'не прошел проверку')
        }
        color={row?.notarial_verification == 1 ? colors.dimGray : '#fff'}
        backgroundColor={
          (row?.notarial_verification == 1 && '#fff') ||
          (row?.notarial_verification == 2 && '#9EC5A9') ||
          (row?.notarial_verification == 3 && '#FFA1A1')
        }
      />
    ),
    width: '15%',
  },

  {
    Header: <img src={dots} alt="Dots" />,
    accessor: 'dots',
  },
];

export const LEADS = [
  {
    Header: 'Дата',
    accessor: (row) => formatDate(fixDateTimezone(row.createdAt), true),
    width: '15%',
  },
  {
    Header: 'Клиент',
    accessor: (row) => row.client?.fullName,
    width: '11%',
  },
  {
    Header: 'Менеджер',
    accessor: 'manager.fullName',
    width: '10%',
  },
  {
    Header: 'Имя',
    accessor: 'fio',
    width: '10%',
  },
  {
    Header: 'Марка и модель',
    accessor: 'markAndModel.title',
    width: '10%',
    disableSortBy: true,
  },

  {
    Header: 'Источник',
    accessor: 'source.title',
    width: '10%',
    disableSortBy: true,
  },
  {
    Header: 'Телефон',
    accessor: (row) => formatPhoneNumber(row.phone),
    width: '10%',
    disableSortBy: true,
  },
  {
    Header: 'E-mail',
    accessor: 'email',
    width: '10%',
    disableSortBy: true,
  },
  {
    Header: 'Язык',
    accessor: 'client.language',
    width: '10%',
    disableSortBy: true,
  },
  {
    Header: 'Статус',
    accessor: (row) => <Status status={row.status?.title} color={colors.dimGray} />,
    width: '10%',
    disableSortBy: true,
  },
  {
    Header: <img src={dots} alt="Dots" />,
    accessor: 'dots',
  },
];

export const DEALS = ({ onCarClick }) => [
  {
    Header: 'Дата',
    accessor: (row) => formatDate(fixDateTimezone(row.createdAt), true),
    width: '7%',
  },
  {
    Header: 'Клиент',
    accessor: (row) => row?.client?.fullName,
    width: '10%',
  },
  {
    Header: 'Сумма контракта, KZT',
    accessor: (row) => formatNumber(row.sum),
    width: '10%',
  },
  {
    Header: 'Статус сделки',
    accessor: (row) => (row.status?.title ? <Status status={row.status?.title} color={colors.dimGray} /> : null),
    width: '15%',
    disableSortBy: true,
  },
  {
    Header: 'Статус оплаты',
    accessor: (row) =>
      row.statusPayment?.title ? <Status status={row.statusPayment?.title} color={colors.dimGray} /> : null,
    width: '10%',
    disableSortBy: true,
  },
  {
    Header: 'Город',
    accessor: (row) => {
      return (row?.cars?.[0]?.city?.title);
    },
    width: '10%',
    disableSortBy: true,
  },
  {
    Header: 'Автомобили',
    accessor: (row) => {
      return (
        <a
          onClick={() => {
            onCarClick && onCarClick(row?.id);
          }}
        >
          {row?.cars
            ?.map(
              (item) =>
                item?.container?.mark?.name + ' ' + item?.container?.model?.name + ' ' + item?.complectation?.title
            )
            .join(', ')}
        </a>
      );
    },
    width: '10%',
    disableSortBy: true,
  },
  {
    Header: 'Аксессуары',
    accessor: (row) => row.additions,
    width: '12%',
    disableSortBy: true,
  },
  // {
  //   Header: 'Первоначальный взнос',
  //   accessor: (row) => formatNumber(row?.creditAppeal?.initialFee),
  //   width: '10%',
  //   disableSortBy: true,
  // },
  // {
  //   Header: 'Срок кредитования (мес)',
  //   accessor: 'creditAppeal.period',
  //   width: '10%',
  //   disableSortBy: true,
  // },
  // {
  //   Header: 'Ежемесячный платеж',
  //   accessor: (row) => formatNumber(row?.creditAppeal?.paymentForMonth),
  //   width: '10%',
  //   disableSortBy: true,
  // },
  {
    Header: 'Страховка',
    accessor: (row) =>
      !row?.additions?.toLowerCase().trim().includes('страховка') ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', color: '#B45436' }}>
          Нет
          <img src={indicator} />
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>Есть</div>
      ),
    width: '7%',
    disableSortBy: true,
  },
  {
    Header: 'Ответственный менеджер',
    accessor: (row) => (row.manager ? row.manager?.name + ' ' + row.manager?.surname : null),
  },
  {
    Header: (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <img src={dots} alt="Dots" />
      </div>
    ),
    accessor: 'dots',
  },
];
export const NOTARY_DEALS = [
  {
    Header: 'Дата',
    accessor: (row) => formatDate(fixDateTimezone(row.createdAt), true),
    width: '15%',
  },
  {
    Header: 'Клиент',
    accessor: (row) => row?.client?.fullName,
    width: '20%',
  },
  {
    Header: 'Сумма контракта, KZT',
    accessor: (row) => formatNumber(row.sum),
    width: '15%',
  },
  {
    Header: 'Доверенность',
    accessor: (row) => (
      <Status
        status={row?.power_of_attorney ? 'получено' : 'ожидает загрузки'}
        color={colors.white}
        backgroundColor={row?.power_of_attorney ? '#9EC5A9' : '#FFA1A1'}
      />
    ),
    width: '20%',
  },
  {
    Header: 'Статус сделки',
    accessor: (row) => (row.status?.title ? <Status status={row.status?.title} color={colors.dimGray} /> : null),
    width: '30%',
    disableSortBy: true,
  },
  {
    Header: (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <img src={dots} alt="Dots" />
      </div>
    ),
    accessor: 'dots',
  },
];
export const NOTARY_DEALS_CREDIT = [
  {
    Header: 'Дата',
    accessor: (row) => formatDate(fixDateTimezone(row.createdAt), true),
    width: '15%',
  },
  {
    Header: 'Клиент',
    accessor: (row) => row?.client?.fullName,
    width: '10%',
  },
  {
    Header: 'Сумма контракта, KZT',
    accessor: (row) => formatNumber(row.sum),
    width: '10%',
  },
  {
    Header: 'Согласие супруга',
    accessor: (row) => (
      <Status
        status={row?.spouse_consent ? 'получено' : 'ожидает загрузки'}
        color={colors.white}
        backgroundColor={row?.spouse_consent ? '#9EC5A9' : '#FFA1A1'}
      />
    ),
    width: '20%',
  },
  {
    Header: 'Доверенность',
    accessor: (row) => (
      <Status
        status={row?.power_of_attorney ? 'получено' : 'ожидает загрузки'}
        color={colors.white}
        backgroundColor={row?.power_of_attorney ? '#9EC5A9' : '#FFA1A1'}
      />
    ),
    width: '20%',
  },
  {
    Header: 'Статус сделки',
    accessor: (row) => (row.status?.title ? <Status status={row.status?.title} color={colors.dimGray} /> : null),
    width: '30%',
    disableSortBy: true,
  },
  {
    Header: (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <img src={dots} alt="Dots" />
      </div>
    ),
    accessor: 'dots',
  },
];

export const EQUIPMENTS = [
  {
    Header: 'Наименование',
    accessor: 'title',
    width: '50%',
  },
  {
    Header: 'Ед. изм.',
    accessor: () => 'штука',
    width: '17%',
    disableSortBy: true,
  },
  {
    Header: 'Количество',
    accessor: 'count',
    width: '17%',
  },
  {
    Header: 'Цена, KZT',
    accessor: (row) => formatNumber(row.price),
    width: '17%',
  },
  {
    Header: <img src={dots} alt="Dots" />,
    accessor: 'dots',
  },
];

export const ADDITIONAL_SERVICES = [
  {
    Header: 'Название',
    accessor: 'title',
    width: '40%',
  },
  {
    Header: 'Ед. изм.',
    accessor: () => 'услуга',
    width: '18%',
    disableSortBy: true,
  },
  {
    Header: 'Цена, KZT',
    accessor: (row) => formatNumber(row.price),
    width: '18%',
  },
  {
    Header: 'Город',
    accessor: (row) => row?.city?.title,
    width: '18%',
  },
  {
    Header: <img src={dots} alt="Dots" />,
    accessor: 'dots',
  },
];

export const CARS = [
  {
    Header: 'Марка',
    accessor: 'mark',
    width: '8%',
  },
  {
    Header: 'Модель',
    accessor: 'model',
    width: '8%',
  },
  {
    Header: 'Комплектация',
    accessor: 'complectation.title',
    width: '8%',
  },
  {
    Header: 'Год выпуска',
    accessor: 'issueYear',
    width: '5%',
  },
  {
    Header: 'VIN',
    accessor: 'vin',
    width: '11%',
  },
  {
    Header: 'Дилерский центр',
    accessor: 'city.title',
    width: '11%',
    disableSortBy: true,
  },
  {
    Header: 'Цвет',
    accessor: (row) => (
      <div style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
        <div
          style={{
            backgroundColor: row.color,
            width: '12px',
            height: '12px',
            borderRadius: '100%',
          }}
        />
        <p>{COLORS.find((item) => row?.color === item?.value)?.label || row?.color}</p>
      </div>
    ),
    width: '11%',
    disableSortBy: true,
  },
  {
    Header: 'РРЦ, KZT',
    accessor: (row) => formatNumber(row.price),
    width: '11%',
  },
  {
    Header: 'Тип двигателя',
    accessor: 'complectation.engine.title',
    width: '7%',
    disableSortBy: true,
  },
  {
    Header: 'Статус',
    accessor: (row) => (
      <Status status={checkStockCar(row?.checkStock) ? 'В наличии' : 'Нет в наличии'} color={colors.dimGray} />
    ),
    width: '11%',
    disableSortBy: true,
  },
  {
    Header: <img src={dots} alt="Dots" />,
    accessor: 'dots',
    width: '11%',
  },
];
export const CARS_SELL_EQUIPMENT = [
  {
    Header: 'Марка',
    accessor: 'mark',
    width: '16%',
  },
  {
    Header: 'Модель',
    accessor: 'model',
    width: '16%',
  },
  {
    Header: 'Комплектация',
    accessor: 'complectation.title',
    width: '14%',
  },
  {
    Header: 'Год выпуска',
    accessor: 'issueYear',
    width: '14%',
  },
  {
    Header: 'Дилерский центр',
    accessor: 'city.title',
    width: '14%',
    disableSortBy: true,
  },
  {
    Header: 'Доп оборудование',
    accessor: 'custom__equipments',
    width: '25%',
  },
  {
    Header: <img src={dots} alt="Dots" />,
    accessor: 'dots',
    width: '11%',
  },
];

export const CAR_DEAL = [
  {
    Header: 'Дата',
    accessor: (row) => formatDate(fixDateTimezone(row.createdAt), true),
    width: '13%',
  },
  {
    Header: 'Сумма контракта, KZT',
    accessor: (row) => formatNumber(row.price),
    width: '16%',
  },
  {
    Header: 'Форма оплаты',
    accessor: 'payment_method',
    width: '23%',
    disableSortBy: true,
  },
  {
    Header: 'Статус оплаты',
    accessor: 'payment_status',
    width: '23%',
    disableSortBy: true,
  },
  {
    Header: 'Статус сделки',
    accessor: 'deal_status',
    width: '23%',
    disableSortBy: true,
  },
  {
    Header: <img src={dots} alt="Dots" />,
    accessor: 'dots',
  },
];
export const CAR_EQUIPMENTS = [
  {
    Header: 'Название',
    accessor: 'name',
    width: '33%',
  },
  {
    Header: 'Сумма',
    accessor: (row) => formatNumber(row.price),
    width: '33%',
  },
  {
    Header: 'Кол-во',
    accessor: 'count',
    width: '33%',
    disableSortBy: true,
  },
  {
    Header: <img src={dots} alt="Dots" />,
    accessor: 'dots',
  },
];

export const MARKS = [
  {
    Header: 'Название',
    accessor: 'name',
    width: '100%',
  },
  {
    Header: <img src={dots} alt="Dots" />,
    accessor: 'dots',
  },
];

export const CONTAINERS = [
  {
    Header: 'Название',
    accessor: (row) => `${row?.mark?.name} ${row?.model?.name}`,
    width: '100%',
  },
  {
    Header: <img src={dots} alt="Dots" />,
    accessor: 'dots',
  },
];

export const COMPLECTATIONS = [
  {
    Header: 'Название',
    accessor: 'name',
    width: '50%',
  },
  {
    Header: 'Опции',
    accessor: 'options',
    width: '50%',
  },
  {
    Header: <img src={dots} alt="Dots" />,
    accessor: 'dots',
    style: { width: 200 },
  },
];

export const LEAD_CLIENTS = [
  {
    Header: 'ФИО клиента',
    accessor: 'name',
    width: '12.5%',
  },
  {
    Header: 'ИИН',
    accessor: 'iin',
    width: '12.5%',
  },
  {
    Header: 'VIP',
    accessor: 'vip',
    width: '12.5%',
    disableSortBy: true,
  },
  {
    Header: 'Компания',
    accessor: 'company',
    width: '12.5%',
    disableSortBy: true,
  },
  // {
  //   Header: 'Должность',
  //   accessor: 'employe',
  // },
  {
    Header: 'Телефон',
    accessor: (row) => formatPhoneNumber(row.phone),
    width: '12.5%',
    disableSortBy: true,
  },
  {
    Header: 'E-mail',
    accessor: 'email',
    width: '12.5%',
    disableSortBy: true,
  },
  {
    Header: 'Посредник',
    accessor: 'broker',
    width: '12.5%',
    disableSortBy: true,
  },
  {
    Header: 'Язык',
    accessor: 'language',
    width: '12.5%',
    disableSortBy: true,
  },
  {
    Header: <img src={dots} alt="Dots" />,
    accessor: 'dots',
  },
];

export const CLIENT_RECORDS = [
  {
    Header: ' ',
    accessor: 'date',
    width: '16.6%',
  },
  {
    Header: ' ',
    accessor: 'services',
    width: '16.6%',
  },
  {
    Header: ' ',
    accessor: (row) => formatPhoneNumber(row.phone),
    width: '16.6%',
  },
  {
    Header: ' ',
    accessor: 'address',
    width: '16.6%',
  },
  {
    Header: ' ',
    accessor: 'time',
    width: '16.6%',
  },
  {
    Header: ' ',
    accessor: 'status',
    width: '16.6%',
  },
  {
    Header: <img src={dots} alt="Dots" />,
    accessor: 'dots',
  },
];

export const REPORTS = [
  {
    Header: 'Дата формирования отчета',
    accessor: (row) => formatDate(fixDateTimezone(row.date), true),
    width: '25%',
  },
  {
    Header: 'Наименование отчета',
    accessor: 'name',
    width: '25%',
  },
  {
    Header: 'Период',
    accessor: 'period',
    width: '25%',
  },
  {
    Header: 'Смотреть отчет',
    accessor: (row) => <File />,
    width: '25%',
  },
  {
    Header: <img src={dots} alt="Dots" />,
    accessor: 'dots',
  },
];

export const RECORDS = [
  {
    Header: 'Дата',
    accessor: (row) => formatDate(fixDateTimezone(row.date)),
    width: '14%',
  },
  {
    Header: 'ФИО клиента',
    accessor: 'fio',
    width: '14%',
  },
  {
    Header: 'Услуги',
    accessor: 'type.title',
    width: '14%',
    disableSortBy: true,
  },
  {
    Header: 'Телефон',
    accessor: (row) => formatPhoneNumber(row.phone),
    width: '14%',
    disableSortBy: true,
  },
  {
    Header: 'Адрес ДЦ',
    accessor: '',
    width: '14%',
    disableSortBy: true,
  },
  {
    Header: 'Дата и время',
    accessor: (row) => formatDate(fixDateTimezone(row.date), true),
    width: '14%',
  },
  {
    Header: 'Статус',
    accessor: (row) => <Status status={row?.status?.title} color={colors.dimGray} />,
    width: '14%',
    disableSortBy: true,
  },
  {
    Header: <img src={dots} alt="Dots" />,
    accessor: 'dots',
  },
];

export const DOCUMENTS = [
  {
    Header: 'ID',
    accessor: 'id',
    width: '4%',
  },
  {
    Header: 'Название',
    accessor: 'type.title',
    width: '48%',
  },
  {
    Header: 'Ссылка',
    accessor: (row) => (
      <a target="_blank" href={row.url}>
        {row.url}
      </a>
    ),
    width: '48%',
  },
  {
    Header: <img src={dots} alt="Dots" />,
    accessor: 'dots',
  },
];
