import { useState } from 'react';
import cancelLoanApplication from '../api/bank/cancelLoanApplication';
import Button from './Button/Button';
import Modal from './Modal/Modal';
import DocsInputNew from './Inputs/DocsInput/DocsInputNew';
import alert from '../helpers/alert';
import uploadDocumentToDeal from '../api/deals/uploadDocumentToDeal';
import { DOCUMENT_TYPES_VEHICLE_RETURN_CONFIRMATION_LETTER } from '../constants';
import LoaderInline from './Loader/LoaderInline';

const CancelCreditFunding = ({ buttonText = 'Отменить заявку и финансирование', dealId, bankOwnerId, chunkId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [document, setDocument] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    if (!document || !dealId || !chunkId || !bankOwnerId) {
      const errors = {
        document: 'Необходимо загрузить документ',
        dealId: 'Не найден ID сделки',
        chunkId: 'Не найден chunkId',
        bankOwnerId: 'Не найден bankOwnerId'
      };
      return alert.error(errors[Object.keys({ document, dealId, chunkId, bankOwnerId }).find(key => !eval(key))]);
    }
    setLoading(true);
    try {
      await uploadDocumentToDeal({
        dealId,
        fileTypeId: DOCUMENT_TYPES_VEHICLE_RETURN_CONFIRMATION_LETTER,
        file: document,
      });

      await cancelLoanApplication({ bankOwnerId, chunkId });
      alert.success('Заявка успешно отменена');
      setIsOpen(true);
    } catch (e) {
      alert.error(e?.response?.data?.message || 'Произошла ошибка при отмене заявки');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button fill onClick={() => setIsOpen(true)}>
        {buttonText}
      </Button>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        contentStyle={{
          maxHeight: 300,
          maxWidth: 520,
        }}
        title="Вы действительно хотите отменить заявку?"
      >
        {loading ? (
          <LoaderInline />
        ) : (
          <>
            <div
              style={{
                marginBottom: 20,
              }}
            >
              <DocsInputNew
                text="Письмо подтверждение о возврате транспортного средства"
                label="Загрузить документ"
                id="письмо"
                value={document}
                onChange={setDocument}
                required
              />
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridGap: '10px',
                marginTop: 'auto',
              }}
            >
              <Button fill onClick={handleClick}>
                Подтвердить
              </Button>
              <Button fill onClick={() => setIsOpen(false)}>
                Отмена
              </Button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default CancelCreditFunding;
