import React from 'react';
import User from '../User/User';
import styles from './header.module.css';

// icons
import alert from '../../assets/header/alert.svg';
// import chat from '../../assets/header/chat.svg';

import { textStyles } from '../../constants/textStyles';
// import Chat from '../Chat/Chat';

const Header = (props) => {
  // const [isOpen, setIsOpen] = useState(false)
  return (
    <header>
      <div className={styles.title}>
        <img src={props.icon} alt="Icon" />
        <p style={textStyles.headerTitle}>{props.title}</p>
      </div>
      <div className={styles.flex}>      
        <button>
          <img src={alert} alt="Alert" />
        </button>
        {/* <button onClick={() => setIsOpen(true)}>
          <img src={chat} alt="Chat" />
        </button> */}
        <User />
      </div>
      {/* <Chat isOpen={isOpen} onRequestClose={() => setIsOpen(false)}/> */}
    </header>
  );
};

export default Header;
